import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Button, Modal, Popconfirm } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

import DynamicTable from "../components/DynamicTable";
import InputField from "../components/InputField";
import CountryDropdown from "../components/CountryDropdown";
import ToggleSwitch from "../components/ToggleSwitch";
import { setTenants } from "../context/actions/tenantAction";
import { createCompany, getTenants, initDB } from "../services/baseAPI";

const TenantsTable = () => {
	const {
		handleSubmit,
		register,
		formState: { errors },
		control,
	} = useForm();

	const tenants = useSelector((state) => state.allTenants.tenants);
	const dispatch = useDispatch();
	const [isModalVisible, setIsModalVisible] = useState(false);

	const header = [
		{
			title: "",
			dataIndex: "",
			key: "x",
			render: (item) => (
				<span className="connect-tenant-btn">
					<Popconfirm
						placement="bottom"
						title={"Would you like to initialize database?"}
						onConfirm={() => onClickInitDb(item.companyId)}
						okText="Yes"
						cancelText="No"
					>
						{/* {moment().utcOffset(0, false).format()} */}
						{/* {item.companyId} */}
						<div className="init-btn">
							<Button>Initialize</Button>
						</div>
						{/* <PlusCircleOutlined style={{ fontSize: "20px", color: "#08c" }} /> */}
					</Popconfirm>
				</span>
			),
		},
		{
			title: "ID",
			dataIndex: "companyId",
			key: "companyId",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: "Industry",
			dataIndex: "industry",
			key: "industry",
		},
		{
			title: "Domain",
			dataIndex: "domain",
			key: "domain",
		},
		{
			title: "Email",
			dataIndex: "emailAddress",
			key: "emailAddress",
		},
		{
			title: "Country",
			dataIndex: "country",
			key: "country",
		},
		{
			title: "Address",
			dataIndex: "addressLine1",
			key: "addressLine1",
		},

		{
			title: "API Name",
			dataIndex: "apiName",
			key: "apiName",
		},
		{
			title: "Fiscal Last Day",
			dataIndex: "fiscalLastDay",
			key: "fiscalLastDay",
		},

		{
			title: "Created At",
			dataIndex: "createdAt",
			key: "createdAt",
		},
		{
			title: "Updated At",
			dataIndex: "updatedAt",
			key: "updatedAt",
		},
	];

	const onSubmit = async (data, e) => {
		const data2 = {
			...data,
			createdAt: moment().utcOffset(0, false).format(),
		};

		const res = await createCompany(data2).catch((err) => {
			console.log("Error: " + err);
		});

		if (res && res.status === 200) {
			fetchTenants();
			setIsModalVisible(false);
			e.target.reset();
		}
	};

	const onClickInitDb = (companyId) => {
		initDB(companyId);
	};

	const onClickAdd = () => {
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const fetchTenants = async () => {
		const response = await getTenants().catch((err) => {
			console.log(err);
		});
		if (response) {
			dispatch(setTenants(response.data));
		}
	};

	useEffect(() => {
		fetchTenants(); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<div className="tenant-button">
				<Button type="primary" size="large" onClick={onClickAdd}>
					<div className="btn-icon-lbl">
						<PlusOutlined />
						Add Tenant
					</div>
				</Button>

				<Modal
					title="Add Tenant"
					visible={isModalVisible}
					width={900}
					footer={null}
					onCancel={handleCancel}
				>
					<form
						className="form-fields modal-field-body"
						onSubmit={handleSubmit(onSubmit)}
					>
						<Row>
							<Col md={6} sm={12}>
								<InputField
									label="Name"
									name="name"
									error={errors.name}
									register={register}
									required={{ value: true, message: "This field is required" }}
								/>
							</Col>
							<Col md={6} sm={12}>
								<InputField
									label="Domain"
									name="domain"
									error={errors.domain}
									register={register}
									required={{ value: true, message: "This field is required" }}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={6} sm={12}>
								<InputField label="Email" name="email" register={register} />
							</Col>
							<Col md={6} sm={12}>
								<InputField
									label="Phone Number"
									name="phoneNumber"
									register={register}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={4} sm={12}>
								<InputField
									label="Date Format"
									name="dateFormat"
									register={register}
								/>
							</Col>
							<Col md={4} sm={12}>
								<InputField
									label="Time Format"
									name="timeFormat"
									register={register}
								/>
							</Col>
							<Col md={4} sm={12}>
								<InputField
									label="timezone Format"
									name="timezoneFormat"
									register={register}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={4} sm={12}>
								<InputField
									label="accounting Period"
									name="accountingPeriod"
									register={register}
								/>
							</Col>
							<Col md={4} sm={12}>
								<InputField
									label="Last day of fiscal year"
									name="fiscalLastDay"
									register={register}
								/>
							</Col>
							<Col md={4} sm={12}>
								<ToggleSwitch
									label="last day is month end"
									defaultChecked
									size="large"
									name="lastDayIsMonthEnd"
									control={control}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={4} sm={12}>
								<InputField
									label="VDAC"
									name="vdac"
									register={register}
									pattern={{
										value: /^[0-9]*$/,
										message: "This must be a number.",
									}}
									required={{ value: true, message: "This field is required" }}
									error={errors.vdac}
								/>
							</Col>
							<Col md={8} sm={12}>
								<InputField
									label="Industry"
									name="industry"
									register={register}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={12} sm={12}>
								<InputField
									label="Address Line 1"
									name="addressLine1"
									register={register}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={12} sm={12}>
								<InputField
									label="address Line 2"
									name="addressLine2"
									register={register}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={4} sm={12}>
								<InputField label="city" name="city" register={register} />
							</Col>
							<Col md={3} sm={12}>
								<InputField label="state" name="state" register={register} />
							</Col>
							<Col md={3} sm={12}>
								<CountryDropdown
									label="country"
									name="country"
									control={control}
								/>
							</Col>
							<Col md={2} sm={12}>
								<InputField
									label="zip Code"
									name="zipCode"
									register={register}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={4} sm={12}>
								<InputField
									label="Email Domain"
									name="emailDomain"
									register={register}
									pattern={{
										value: /^[0-9]*$/,
										message: "This must be a number.",
									}}
									required={{ value: true, message: "This field is required" }}
									error={errors.emailDomain}
								/>
							</Col>
							<Col md={4} sm={12}>
								<InputField
									label="Email Address"
									name="emailAddress"
									register={register}
								/>
							</Col>
							<Col md={4} sm={12}>
								<InputField
									label="email Name"
									name="emailName"
									register={register}
								/>
							</Col>
						</Row>
						<div className="modal-footer-alt">
							<Button htmlType="submit" type="primary" className="submit-modal">
								Submit
							</Button>
						</div>
					</form>
				</Modal>
			</div>
			<DynamicTable
				data={tenants}
				columnHeader={header}
				scroll={{ x: 1300 }}
				pagination={{
					pageSize: 25,
					total: tenants.length,
				}}
			/>
		</Fragment>
	);
};
export default TenantsTable;
