import { ActionTypes } from "../constants/action-types";

export const setTenants = (tenants) => {
	return {
		type: ActionTypes.SET_TENANTS,
		payload: tenants,
	};
};

export const selectedTenants = (tenant) => {
	return {
		type: ActionTypes.SELECTED_TENANTS,
		payload: tenant,
	};
};
