import { ActionTypes } from "../constants/action-types";

const initalState = {
	searchText: "",
	users: [],
	filteredUsers: [],
};

export const userReducer = (state = initalState, { type, payload }) => {
	switch (type) {
		case ActionTypes.SET_USERS:
			return { ...state, users: payload };
		case ActionTypes.FILTERED_USERS:
			return Object.assign({}, state, {
				searchText: ActionTypes.SET_SEARCH_TEXT,
			});
		default:
			return state;
	}
};
