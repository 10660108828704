import React from "react";
import { Table } from "antd";

import "antd/dist/antd.css";
const DynamicTable = ({
	columnHeader,
	data,
	tableProps,
	pagination,
	scroll,
}) => {
	return (
		<Table
			{...tableProps}
			dataSource={data}
			columns={columnHeader}
			pageSizeOptions={[25, 35, 50, 100]}
			pagination={pagination}
			scroll={scroll}
		/>
	);
};
export default DynamicTable;
