import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./context/store";
import "./index.css";

import Login from "./containers/Login";
import SuperAdmin from "./containers/SuperAdmin";

const App = () => {
	return (
		<Provider store={store}>
			<Router>
				<Routes>
					<Route path="/" element={<Login />} />
					<Route path="/superAdmin" element={<SuperAdmin />} />
				</Routes>
			</Router>
		</Provider>
	);
};

export default App;
