import { ActionTypes } from "../constants/action-types";

const initalState = {
	token: "",
};
export const loginReducer = (state = initalState, { type, payload }) => {
	switch (type) {
		case ActionTypes.SET_TOKEN:
			return { ...state, token: payload };
		default:
			return state;
	}
};
