import { combineReducers } from "redux";
import { tenantReducer } from "./tenantReducer";
import { userReducer } from "./userReducer";
import { loginReducer } from "./loginReducer";

const reducers = combineReducers({
	allTenants: tenantReducer,
	allUsers: userReducer,
	allToken: loginReducer,
});

export default reducers;
