import axios from "axios";

const baseURL = process.env.REACT_APP_SUPER_ADMIN_URL;
const token = localStorage.getItem("token");
let headers = {};

if (token) {
	headers = {
		Authorization: `${token}`,
		Origin: window.location.href,
		"Access-Control-Allow-Origin": "*",
	};
}
axios.defaults.withCredentials = true;
const axiosIstance = axios.create({
	baseURL: baseURL,
	headers,
});

export default axiosIstance;
