import { ActionTypes } from "../constants/action-types";

const initalState = {
	tenants: [],
};
export const tenantReducer = (state = initalState, { type, payload }) => {
	switch (type) {
		case ActionTypes.SET_TENANTS:
			return { ...state, tenants: payload };
		default:
			return state;
	}
};
