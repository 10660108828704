import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
// import { useDispatch } from "react-redux";
import { login } from "../../services/baseAPI";

const Login = () => {
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm();
	const [showError, setShowError] = useState(false);
	const navigate = useNavigate();
	// const dispatch = useDispatch();//update

	const onSubmit = (data) => {
		login(data.username, data.password)
			.then((res) => {
				if (res && res.status === 200) {
					localStorage.setItem("token", res.config.headers.Authorization);
					navigate("/superAdmin");
				}
			})
			.catch((err) => {
				console.log("Error: " + err);
				setShowError(true);
			});
	};

	return (
		<div className="login-container">
			<div className="login-form">
				<div className="login-title">
					Welcome back to <span className="super-ad-lbl">Super Admin</span>
				</div>
				<div>
					<div className="login-subTitle">
						Enter the following details to get you started.
					</div>
				</div>
				<form className="login-field-ctr" onSubmit={handleSubmit(onSubmit)}>
					<input
						placeholder="Username"
						className="login-fields login-user"
						name="username"
						{...register("username", {
							required: true,
						})}
					></input>
					{errors && errors.username && (
						<div data-testid="error-msg-username" className="field-error">
							This field is required{" "}
						</div>
					)}
					<input
						placeholder="Password"
						className="login-fields login-pass"
						name="password"
						type="password"
						{...register("password", {
							required: true,
						})}
					></input>
					{errors && errors.password && (
						<div data-testid="error-msg-pass" className="field-error">
							This field is required
						</div>
					)}
					<input
						type="submit"
						className="login-submit-btn like"
						value="Login"
					/>
					{showError && (
						<div className="invalid-login">Invalid username and password</div>
					)}
				</form>
			</div>
			<div className="login-right">
				<div className="balloon"></div>
				<img src="assets/images/login.png" alt="login" />
			</div>
		</div>
	);
};

export default Login;
