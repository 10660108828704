import React, { useState } from "react";
import { Layout, Avatar } from "antd";
import { UserOutlined, BellOutlined } from "@ant-design/icons";
import { faHome, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // eslint-disable-next-line react-hooks/exhaustive-deps
import TenantsTable from "../../layouts/TenantsTable";
import UsersTable from "../../layouts/UsersTable";
import MenuComponent from "../../components/Menu";

const { Content, Sider } = Layout;

const SuperAdmin = () => {
	const [menuKey, setMenuKey] = useState(0);

	const menuItem = [
		{
			title: "Tenants",
			icon: <FontAwesomeIcon icon={faHome} />,
			panel: <TenantsTable />,
		},
		{
			title: "Users",
			icon: <FontAwesomeIcon icon={faUsers} />,
			panel: <UsersTable />,
		},
	];

	const onClickMenu = (item) => {
		setMenuKey(item.key);
	};

	return (
		<Layout>
			<Layout>
				<Sider
					width={200}
					className="site-layout-background"
					style={{ position: "fixed", height: "100vh", left: 0 }}
				>
					<div className="comp-container">
						<div className="comp-logo">
							<img src="assets/images/comp.jpg" alt="logo" />
						</div>
						<div className="comp-name">Super Admin</div>
					</div>

					<MenuComponent items={menuItem} onClick={onClickMenu} />
				</Sider>
				<Layout style={{ padding: "0 24px 24px", overflow: "initial" }}>
					<Content
						className="site-layout-background"
						style={{
							padding: 24,
							margin: 0,
							minHeight: "100vh",
							marginLeft: 200,
						}}
					>
						<div className="header-container">
							<div className="cont-title">
								{menuItem.length > 0 && menuItem[menuKey].title}
								<i className="far fa-alarm-clock"></i>
							</div>
							<div className="header-user">
								<div style={{ marginRight: "12px" }}>
									<BellOutlined />
								</div>
								<Avatar
									style={{ backgroundColor: "#87d068" }}
									icon={<UserOutlined />}
								/>
								<div className="user">Jemimah Rontos</div>
							</div>
						</div>
						<div className="cont-body">
							{menuItem.length > 0 && menuItem[menuKey].panel}
						</div>
					</Content>
				</Layout>
			</Layout>
		</Layout>
	);
};

export default SuperAdmin;
