import { ActionTypes } from "../constants/action-types";

export const setUsers = (users) => {
	return {
		type: ActionTypes.SET_USERS,
		payload: users,
	};
};

export const filteredUsers = (users) => {
	return {
		type: ActionTypes.FILTERED_USERS,
		payload: users,
	};
};
