import React from "react";
import { Switch } from "antd";
import { Controller } from "react-hook-form";

const ToggleSwitch = ({
	control,
	rules,
	name,
	label,
	defaultChecked,
	onChange,
	size,
}) => {
	return (
		<div className="switch-container">
			<div className="input-lbl">{label}</div>
			<Controller
				control={control}
				rules={rules}
				name={name}
				render={({ field: { onChange } }) => (
					<Switch
						defaultChecked={defaultChecked}
						onChange={onChange}
						size={size}
					/>
				)}
			></Controller>
		</div>
	);
};
export default ToggleSwitch;
