import React from "react";
import { Select } from "antd";
import { Controller } from "react-hook-form";
import { countries } from "../utils/country";
const { Option } = Select;

const CountryDropdown = ({
	label,
	style,
	name,
	rules,
	control,
	defaultValue,
}) => {
	return (
		<div className="selector-container country-dd-container">
			<div className="input-lbl">{label}</div>
			<Controller
				name={name}
				rules={rules}
				control={control}
				render={({ field: { onChange } }) => (
					<Select defaultValue={defaultValue} style={style} onChange={onChange}>
						{Object.entries(countries).map(([key, value]) => {
							return (
								<Option key={key} value={value}>
									{value}
								</Option>
							);
						})}
					</Select>
				)}
			/>
		</div>
	);
};

export default CountryDropdown;
