import axios from "axios";
import axiosIstance from "../helpers/axios";
const BASE_URL = process.env.REACT_APP_SUPER_ADMIN_URL;

export const login = (username, password) => {
	const token = Buffer.from(`${username}:${password}`, "utf8").toString(
		"base64"
	);
	axios.defaults.withCredentials = true;
	return axios.get(BASE_URL, {
		headers: {
			Authorization: `Basic ${token}`,
			Origin: window.location.href,
			"Access-Control-Allow-Origin": "*",
		},
	});
};

export const createCompany = (data) => {
	return axiosIstance.post("/company", data);
};

export const getTenants = () => {
	return axiosIstance.get("/company").catch((err) => {
		console.log(err);
	});
};

export const initDB = (companyId) => {
	return axiosIstance
		.get(`${companyId}`)
		.then((res) => {
			console.log("inithere", res);
		})
		.catch((err) => {
			console.log("Error : " + err);
		});
};

export const getUsers = (companyId) => {
	return axiosIstance.get(`/user/company/${companyId}`);
};
