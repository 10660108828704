import React, { Component } from "react";
import "antd/dist/antd.css";
import { Layout, Menu } from "antd";

const { Sider } = Layout;

export class MenuComponent extends Component {
	constructor(props) {
		super(props);
		// Don't do this!
		this.state = { color: props.color };
	}

	render() {
		const { items, onClick } = this.props;
		return (
			<Sider
				width={200}
				className="site-layout-background"
				style={{ position: "fixed", height: "100vh", left: 0 }}
			>
				<Menu
					mode="inline"
					defaultSelectedKeys={"0"}
					style={{ height: "100%", borderRight: 0 }}
					onClick={onClick}
				>
					{items &&
						items.map((item, i) => (
							<Menu.Item key={i} icon={item.icon}>
								{item.title}
							</Menu.Item>
						))}
				</Menu>
			</Sider>
		);
	}
}
export default MenuComponent;
