import React, { Fragment, useState, useEffect } from "react";
import "antd/dist/antd.css";
import DynamicTable from "../components/DynamicTable";
import { Input, Button } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setUsers } from "../context/actions/userAction";
import { getTenants, getUsers } from "../services/baseAPI";

const UsersTable = () => {
	const users = useSelector((state) => state.allUsers.users);
	const dispatch = useDispatch();

	const [filterText, setFilterText] = useState("");
	// const [users, setUsers] = useState(sampleData);
	const header = [
		{
			title: "User ID",
			dataIndex: "userId",
			key: "userId",
		},
		{
			title: "User Name",
			dataIndex: "username",
			key: "username",
			sorter: (a, b) => a.username.localeCompare(b.username),
		},
		{
			title: "Tenant ID",
			dataIndex: "companyId",
			key: "companyId",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "Created Date",
			dataIndex: "createdAt",
			key: "createdAt",
		},
		{
			title: "Updated Date",
			dataIndex: "updatedAt",
			key: "updatedAt",
		},
		{
			title: "Last Login",
			dataIndex: "lastLogin",
			key: "lastLogin",
		},
	];

	const onClickFilter = () => {
		let filteredData = [];

		if (filterText === "") {
			fetchUsers();
		} else {
			users.forEach((item) => {
				if (item.tenantId.toString().includes(filterText)) {
					filteredData.push(item);
					dispatch(setUsers(filteredData));
				} else {
					dispatch(setUsers([]));
				}
			});
		}
	};

	const onChangeFilterText = (e) => {
		let value = e.target.value;
		setFilterText(value);
	};

	const onKeyDownEnter = (e) => {
		if (e.key === "Enter") {
			onClickFilter();
		}
	};

	const fetchUsers = async () => {
		getTenants()
			.then((res1) => {
				let tenantList = res1.data;
				let userList = [];
				tenantList.forEach((item) => {
					getUsers(item.companyId)
						.then((res2) => {
							userList = [...userList, ...res2.data];
							dispatch(setUsers(userList));
						})
						.catch((err2) => {
							console.log(err2);
						});
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		fetchUsers(); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<div className="filter-container">
				<Input
					placeholder="Filter Tenant ID"
					value={filterText}
					onChange={onChangeFilterText}
					onKeyDown={onKeyDownEnter}
				/>
				<Button
					ghost
					type="primary"
					icon={<FilterOutlined />}
					onClick={onClickFilter}
				></Button>
			</div>
			<DynamicTable data={users} columnHeader={header} />
		</Fragment>
	);
};

export default UsersTable;
