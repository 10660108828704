import React from "react";

import "antd/dist/antd.css";

const InputField = ({
	label,
	register,
	name,
	error,
	onChange,
	//register validations
	required,
	maxLength,
	valueAsNumber,
	pattern,
}) => (
	<div className="input-container">
		<div className="input-lbl">{label}</div>
		<input
			{...register(name, {
				required,
				maxLength: maxLength,
				valueAsNumber: valueAsNumber,
				pattern: pattern,
			})}
			onChange={onChange}
		/>
		{error && <div className="field-error">{error.message}</div>}
	</div>
);

InputField.defaultProps = {
	register: () => {},
};
export default InputField;
